import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';

class SearchInput extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      searchValue: '',

    };
  }

  render() {
    return (
      <input type="text" value={this.props.searchValue} onChange={this.props.onChange} onKeyPress={this.props.onKeyPress} placeholder={this.props.t('searchbox_placeholder')} />
    );
  }
}

class SearchButton extends Component
{
  render() {
    return (
      <input type="submit" value={this.props.t('searchbox_button')} onClick={this.props.onSubmit} />
    );
  }
}

class SearchForm extends Component {

  constructor(props) {
    super(props);
    this.state = { searchValue: '' };

    //Init Timer
    this.searchTimer = null;

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onKeyPress(e)
  {
    if(e.which === 13)
    {
      //Clear Timer
      clearTimeout(this.searchTimer);

      //Search
      this.props.executeSearch(this.state.searchValue);
    }
  }

  onInputChange(e)
  {
    this.setState({
      searchValue: e.target.value,
    });
    this.startSearchTimer(e.target.value);
  }

  startSearchTimer(searchValue)
  {
    //Clear Timer
    clearTimeout(this.searchTimer);

    //Run Request
    if (searchValue.length > 0) {
      let searchTerm = searchValue;
      //Start a timer on key down
      this.searchTimer = setTimeout(() => {
        this.props.executeSearch(searchTerm)
      }, 1000);
    }
  }

  render() {
    return (
      <section className="bg-light search">
        <div className="container">
          <h2><Trans i18nKey="searchbox_h2" /></h2>
          <p><Trans i18nKey="searchbox_description" /></p>

          <div className="search_container">
            <SearchInput t={this.props.t} onChange={this.onInputChange} onKeyPress={this.onKeyPress} searchValue={this.searchValue} />
            <SearchButton t={this.props.t} onSubmit={this.props.onSubmit} />
          </div>

        </div>
      </section>
    )
  }

}

export default withTranslation()(SearchForm);