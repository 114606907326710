import React from 'react';
import ReactDOM from 'react-dom';
import App from './databasestandalone';
import * as serviceWorker from './serviceWorker';
import './helpers/i18n';

/*

https://reacttraining.com/react-router/web/guides/quick-start

*/

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
