import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class ErrorMessage extends Component
{
  render()
  {
    return (
      <section className="bg-red">
        <div className="container">
          <h2><Trans i18nKey="error_title">Entschuldigung!</Trans></h2>
          <Trans i18nKey="error_text">Ups, da ist etwas schief gegangen :-( Wir können die Datenbank zur Zeit nicht ansprechen. Vielleicht hilft folgende Fehlermeldung:</Trans><br />
          <i>{this.props.message}</i>
        </div>
    </section>
    )
  }
}

export default ErrorMessage;
