import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Article extends Component {
  render() {
    const art = this.props.data;
    return (
      <article>
        <hr />
        <a href={art.url} target="_blank" rel="noopener noreferrer">
        <section className="article_container">
          <div className="article_image">
            <img src={`/img/articles/${art.image}`} alt="" />
            <img src={`/img/articles_logos/${art.medium}.png`} alt="" className="article_logo" />
          </div>
          <div className="article_text">
            <h3>{art.title}</h3>
            <p>{art.lead}</p>
            <p className="link_bold"><Trans i18nKey="read_article">Artikel lesen</Trans></p>
          </div>
        </section>
        </a>
      </article>
    )
  }
}

class ArticleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      page: 0
    }
  }

  fetchData() {
    fetch("/articlefeed.json")
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        this.setState({
          articles: json
        })
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {

    if(this.state.articles.length === 0)
      return null;

    const pageSize = 5
    const articles = this.state.articles
      .filter((article, i) => i < this.state.page * pageSize + pageSize)
      .map(article => <Article data={article} key={article.url} />)

    return (
      <div className="container bg-white">
        <h2><Trans i18nKey="articles_h2">Relevante Artikel</Trans></h2>
        <p className="lead">
          <Trans i18nKey="articles_intro">Was hat es mit diesen Pharmageldern auf sich? Folgende Artikel könnten Sie interessieren.</Trans>
        </p>

        {articles}

        {articles.length > this.state.page * (pageSize + 1) && (
          <div className='articlelist_more'>
            <a onClick={() => this.setState({'page': this.state.page + 1})}>Weitere Artikel anzeigen</a>
          </div>
        )}
      </div>
    )
  }
}

export default ArticleList;