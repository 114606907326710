import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Helpers from '../helpers/helpers.js';
import { Trans } from 'react-i18next';
import NumberFormat from 'react-number-format';

function Pharma(props) {
  return (
    <div className="results_list">
      <Link to={`/pharma/${props.data.id}-${Helpers.formatLink(props.data.name)}.html`} onClick={props.clearSearch}>
        <div className="results_container">
          <div className="person"><strong>{props.data.name}</strong></div>
          <div className="specific"><strong><NumberFormat value={props.data.total} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} /> <Trans i18nKey="currency">Fr.</Trans></strong></div>
        </div>
      </Link>
    </div >
  )
}

class PharmaList extends Component {

  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  render() {
    if (this.props.data.length > 0) {
      const list = this.props.data.map((pharma) =>
        <Pharma data={pharma} key={pharma.id} clearSearch={this.props.clearSearch} />
      );

      return (
        <div className="results_block">
          {list}
        </div>
      )
    }
    else {
      return null;
    }
  }

}

export default PharmaList;