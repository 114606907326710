import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import * as moment from 'moment';
import 'moment/locale/fr-ch';
import 'moment/locale/de-ch'; // last loaded locale is default
import Helpers from './../helpers/helpers.js';

class VisitLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    moment.locale(this.props.locale + '-ch');
  }

  fetchData() {
    this.props.apiConnector.apiViewLog(res => {

      //Concat recipients and pharma and take the newest 5

      //Add Source to recipients
      res.data.recipients.forEach(function (entry) {

        entry.source = 'recipient';

        //Add a new key, because pharmas can have the same keys
        entry.key = 10000 + entry.id
      });

      //Add Source to Pharma
      res.data.pharmas.forEach(function (entry) {
        entry.source = 'pharma';
        entry.key = entry.id;
      });

      //Concat
      let data = res.data.recipients.concat(res.data.pharmas);

      //Sort by date
      data.sort((a, b) => {
        let date_a = new Date(a.timestamp);
        let date_b = new Date(b.timestamp);
        return date_a > date_b ? -1 : 1;
      });

      //Slice max 5
      data = data.slice(0, 5);

      this.setState({ data: data });
    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.lastFetch !== this.props.lastFetch) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    if (this.state.data) {
      
      let recipients = this.state.data.map(entry => {
        let since = moment(entry.timestamp).fromNow();
        if (entry.source === 'recipient') {
          return <div className="results_list visits" key={entry.key}><Link to={`/recipient/${entry.id}-${Helpers.formatLink(entry.name)}.html`} > <div className="results_container"> <div className="person"><strong>{entry.name}</strong> <br />{entry.location}</div> <div className="specific"> ({since})</div> </div></Link ></div>
        }
        else {
          return <div className="results_list visits" key={entry.key}><Link to={`/pharma/${entry.id}-${Helpers.formatLink(entry.name)}.html`} > <div className="results_container"> <div className="person"><strong>{entry.name}</strong></div> <div className="specific"> ({since})</div> </div></Link ></div>
        }
      });

      return (
        <div>
          <p><strong><Trans i18nKey="visitlog_title">Zuletzt angesehen</Trans></strong></p>
          {recipients}
        </div>
      )
    }
    else {
      return (
        <div>
          <Trans i18nKey="load">laden...</Trans>
        </div>
      )
    }
  }

}

export default VisitLog;