import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';

import Helpers from '../helpers/helpers';
import Loading from '../atoms/loading.js';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';

class Recipient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  fetchData() {
    this.props.apiConnector.apiRecipient(this.props.recipientId, res => {
      this.setState({
        hasResult: true,
        transactions: res.data.transactions,
        aggregation: res.data.aggregation,
        recipient: res.data.recipient
      });
      if('recipient' in res.data)
        document.title = `${res.data.recipient.name} - pharmagelder.ch`;
      else
        document.title = `pharmagelder.ch`;

    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.recipientId !== this.props.recipientId) {
      this.fetchData();
      // this.props.onRecipientChanged();
    }
  }

  componentDidMount() {
    this.fetchData();
    // this.props.onRecipientChanged();
  }

  render() {
    let map_years = "";

    if (this.state.hasResult) {

      //No Result
      if (!this.state.recipient) {
        return (
          <div className="results_block">
            <Trans i18nKey="recipient_not_found">Adresse nicht gefunde</Trans>
          </div>)
      }

      if (this.state.recipient) {
        map_years = this.state.transactions.sort((a, b) => parseInt(a[0]) < parseInt(b[0]) ? 1 : -1).map(year => {

          //Map Transaction
          const map_transactions = year[1].map(transaction => {
            return (
              <div key={transaction.id} className="results_container">
                <div className="person">
                  {transaction.total} <Link to={`/pharma/${transaction.pharma_id}-${Helpers.formatLink(transaction.pharma_name)}.html`}><strong>{transaction.pharma_name}</strong></Link> <img data-tip={`${this.props.t('address_original')}<br />${transaction.name_original}, ${transaction.address_original}, ${transaction.location_original}`} src="/img/tooltip.png" className="tooltip_logo" alt="" /> - {this.props.t(transaction.category_name)}
                </div>
                <div className="specific">
                  <NumberFormat value={transaction.value} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} decimalScale={2} fixedDecimalScale={true}/>
                </div>
              </div>
            );
          })

          return (
            <div key={year[0]} >

              <p className="details"><Trans i18nKey="recipient_details">Details</Trans> {year[0]}</p>

              <ReactTooltip html={true} place="right" type="light" effect="float" className="tooltip" />
              {map_transactions}
              <hr />

            </div>
          )
        });

        let totalSum = 0;

        let biggestValue = 0;
        this.state.aggregation.forEach((year) => {
          if (year.total > biggestValue) {
            biggestValue = year.total;
          }
        })

        const totalYears = this.state.aggregation.map(year => {

          let myWidth = 0;
          myWidth = Math.round(100 * year.total / biggestValue);
          if (myWidth < 5) {
            myWidth = 0.5; //Prozente
          }

          totalSum += year.total;
          return (
            <div key={year.year} className="table-row">
              <div className="table-cell year">{year.year}</div>
              <div className="table-cell bar"><div className="bar_sum" style={{ width: `${myWidth}%` }}></div></div>
              <div className="table-cell sum">
                <NumberFormat value={year.total} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
              </div>
            </div>
          )
        })

        let { recipient } = this.state;

        //Line break for lazzy people
        const lb = '%0D%0A';

        return (
          <div className="results_block">

            <div className='result_disclaimer_top'>
              <p>{this.props.t("recipient_intro1").replace("{name}", this.state.recipient.name)}</p>
            </div>

            <div className="bg-medium results_info">
              <div className="results_address">
                <h3>{this.state.recipient.name}</h3>
                {!!(this.state.recipient.address) ? `${this.state.recipient.address}, ` : ""} {this.state.recipient.location}
              </div>

              <hr />

              <div className="results_container">
                <div className="person"><h3><Trans i18nKey="recipient_h3">Erhaltene Pharmagelder</Trans></h3></div>
                <div className="specific">
                  <h3><NumberFormat value={totalSum} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} /></h3>
                </div>
              </div>

              <strong>{totalYears}</strong>
            </div>

            {map_years}

            <div className="recipient_error">
              <p className="legende"><b><Trans i18nKey="recipient_disclaimer_source_title" /></b><br />
                <Trans i18nKey="recipient_disclaimer_source_text" />
              </p>

              <p className="legende"><b><Trans i18nKey="recipient_disclaimer_objection_title" /></b><br />
                <Trans i18nKey="recipient_disclaimer_objection_text" /> <a href={`mailto:info@pharmagelder.ch?subject=Pharmagelder&body=${lb}${lb}${lb}Zur Rückverfolgung (bitte nicht entfernen):${lb}${recipient.id} - ${recipient.name} - ${recipient.address}`} target='_blank' rel="noopener noreferrer">info@pharmagelder.ch</a>
              </p>

            </div>
          </div>
        )
      }
    }
    else {
      return <Loading />
    }
  }

}

export default withTranslation()(Recipient);