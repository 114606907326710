import axios from 'axios';
import React from 'react';
import { Trans } from 'react-i18next';


class ApiConnector
{
  constructor(onError)
  {
    this.api_url = process.env.REACT_APP_API_URL;
    this.onError = onError;
  }

  createError(url, e)
  {
    return (
      <span>
      <Trans i18nKey="iframesearch.connectionError">Api konnte nicht angesprochen werden.</Trans> <i>{url}</i><br />
        {e.message}
      </span>
    )
  }

  /*-------------------------------------------------------- */
  //Search
  apiSearch(searchterm, count)
  {
    return Promise.all([this.apiSearchRecipients(searchterm, count), this.apiSearchPharma(searchterm, 5)])
      .catch(e => {
        this.onError(this.createError("search error", e));
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
  }

  /*-------------------------------------------------------- */
  // Recipients

  apiSearchRecipients(searchterm, count)
  {
    const url = `${this.api_url}/recipient/search/${searchterm}/${count}`;
    return axios.get(url);
  }

  apiRecipient(recipientId, callback)
  {
    const url = this.api_url + '/recipient/id/' + recipientId;
    axios.get(url)
      .then(callback)
      .catch(e => {
        this.onError(this.createError(url, e));
      });
  }

  /*-------------------------------------------------------- */
  // Pharma

  apiSearchPharma(searchterm, count)
  {
    const url = `${this.api_url}/pharma/search/${searchterm}/${count}`;
    return axios.get(url);
  }

  apiPharma(recipientId, callback)
  {
    const url = this.api_url + '/pharma/id/' + recipientId;
    axios.get(url)
      .then(callback)
      .catch(e => {
        this.onError(this.createError(url, e));
      });
  }

  /*-------------------------------------------------------- */
  // Others

  apiViewLog(callback)
  {
    const url = this.api_url + '/log/';
    axios.get(url)
      .then(callback)
      .catch(e => {
        this.onError(this.createError(url, e));
      });
  }
}



export default ApiConnector;
