class Helpers
{
  static formatLink(link)
  {
    return link.replaceAll(' ', '-').replaceAll('/', '');
    
  }
  
  static getShortLanguage(lng){
    return lng.split('-')[0];
  }
}

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export default Helpers;