import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PharmaList from '../pharma/pharmalist.js';
import RecipientList from '../recipient/recipientlist.js';

class SearchResults extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      result_pharmas: [],
      result_recipients: []
    };
  }

  fetchData() {
    this.props.apiConnector.apiSearch(this.props.searchTerm, 50).then(e => {
      const merged = Object.assign(e[0].data, e[1].data);
      this.setState({
        result_recipients: merged.recipients,
        result_pharmas: merged.pharmas,
        showNoResults: merged.pharmas.length === 0 & merged.recipients.length === 0
      });
    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.searchTerm !== this.props.searchTerm) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div ref={this.props.refInternal}>
        <div>
          <PharmaList data={this.state.result_pharmas} />
          <RecipientList data={this.state.result_recipients} onScrollTo={this.scrollTo} />
        </div>

        {this.state.showNoResults === 1 &&
          <div className='result_empty'>{this.props.t("search_nothing_found").replace("{name}", this.props.searchTerm)}</div>
        }
      </div>
    )
  }

}

export default withTranslation()(SearchResults);