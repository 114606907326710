import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans, withTranslation } from 'react-i18next';

import Helpers from '../helpers/helpers.js';
import Loading from '../atoms/loading.js';

import NumberFormat from 'react-number-format';

function TopRecipients(props) {

  const recipients = props.recipients.map(recipient => {
    return (
      <div key={recipient.id} className="results_container">
        <div className="person"><Link to={`/recipient/${recipient.id}-${Helpers.formatLink(recipient.name)}.html`}>{recipient.name}</Link> </div>
        <div className="specific"><NumberFormat value={recipient.value} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} /> <Trans i18nKey="currency">Fr.</Trans></div>
      </div>
    )
  });

  return (
    <div>
      {recipients}
    </div>
  )
}

class Pharma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      noAddress: false
    };
  }

  fetchData() {
    this.props.apiConnector.apiPharma(this.props.pharmaId, res => {
      if('pharma' in res.data)
      {
        this.setState(res.data);
        document.title = `${res.data.pharma.name} - pharmagelder.ch`
      }
      else
      {
        console.log("Not FOND")
        this.setState({noAddress: true})
        document.title = `pharmagelder.ch`;
      }
    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.pharmaId !== this.props.pharmaId) {
      this.fetchData();
      this.props.onRecipientChanged();
    }
  }

  componentDidMount() {
    this.fetchData();
    this.props.onRecipientChanged();
  }

  render() {
    if(this.state.noAddress)
    {
      return (
      <div className="results_block">
        <Trans i18nKey="recipient_not_found">Adresse nicht gefunde</Trans>
      </div>)
    }
    else
    {
      if (!this.state.toprecipientscurrentyear) {
        return <Loading />
      }
      else {

        const { hcp, hco } = this.state.toprecipientscurrentyear;
        const { pharma, years, totalcurrentyear, categoriescurrentyear, sources } = this.state;

        // Fast Hack
        if(totalcurrentyear.length === 0)
        {
          totalcurrentyear.push({
            total_hcp: 0,
            total_hco: 0,
            total_rnd: 0,
          })
        }

        const sourcesMap = sources.map(source => {

          let sourceTxt = source.source;
          //sourceTxt = sourceTxt.split("//")[1].split("/")[0];

          return (
            <li key={source.id} > <a href={source.source} target='_blank' rel='noopener noreferrer'>{sourceTxt}</a></li >
          )
        });

        let totalSum = 0;

        let biggestValue = 0;
        years.forEach((year) => {
          if (year.sumValue > biggestValue) {
            biggestValue = year.sumValue;
          }
        })

        const totalYears = years.map(year => {

          let myWidth = 0;
          myWidth = Math.round(100 * year.sumValue / biggestValue);
          if (myWidth < 5) {
            myWidth = 0.5; //Prozente
          }

          totalSum += year.sumValue;
          return (
            <div key={year.year} className="table-row">
              <div className="table-cell year">{year.year}</div>
              <div className="table-cell bar"><div className="bar_sum" style={{ width: `${myWidth}%` }}></div></div>
              <div className="table-cell sum">
                <NumberFormat value={year.sumValue} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
              </div>
            </div>
          )
        })

        const totalCategories = categoriescurrentyear.map(category => {
          return (
            <div key={category.category_name} className="results_container">
              <div className="person">{this.props.t(category.category_name)}</div>
              <div className="specific">
                <NumberFormat value={category.sumValue} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
              </div>
            </div >
          )
        })

        //Line break for lazzy people
        const lb = '%0D%0A';

        const note = this.state.pharma.note ? JSON.parse(this.state.pharma.note) : {}

        return (
          <div className="results_block">

            <div className='result_disclaimer_top'>
              <p>{this.props.t("recipient_intro1").replace("{name}", this.state.pharma.name)}</p>
            </div>

            <div className="bg-medium results_info">

              <div className="results_address">
                <h3>{pharma.name}</h3>
              </div>

              <hr />

              <div className="results_container">
                <div className="person"><h3><Trans i18nKey="pharma_h3">Gezahlte Pharmagelder</Trans></h3></div>
                <div className="specific">
                  <h3><NumberFormat value={totalSum} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} /></h3>
                </div>
              </div>

              <div className="table">{totalYears}</div>

            </div>
            {this.state.pharma.note &&
              <div className='pharmanote'>{['fr', 'ch-fr', 'ch-FR', 'CH-FR'].includes(this.props.i18n.language) ? note.fr : note.de}</div>
            }

            <p className="details"><Trans i18nKey="pharma_actuell">2018 im Detail</Trans></p>
            <div>
              <div key={totalcurrentyear[0].total_hcp} className="results_container">
                <div className="person"><Trans i18nKey="pharma_for_hcp">Zahlungen an HCP</Trans></div>
                <div className="specific">
                  <NumberFormat value={totalcurrentyear[0].total_hcp} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
                </div>
              </div>
              <div className="results_container">
                <div className="person"><Trans i18nKey="pharma_for_hco">Zahlungen an HCO</Trans></div>
                <div className="specific">
                  <NumberFormat value={totalcurrentyear[0].total_hco} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
                </div>
              </div>
              <div className="results_container">
                <div className="person"><Trans i18nKey="pharma_for_rnd">Zahlungen an RND</Trans></div>
                <div className="specific">
                  <NumberFormat value={totalcurrentyear[0].total_rnd} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} suffix={`${this.props.t('currency')}`} />
                </div>
              </div>
            </div>

            {hcp &&
              <React.Fragment>
                <hr />
                <p className="details"><Trans i18nKey="pharma_top_hcp">Top HCP</Trans></p>
                <TopRecipients recipients={hcp} />
              </React.Fragment>
            }

            {hco &&
              <React.Fragment>
              <hr />
              <p className="details"><Trans i18nKey="pharma_top_hco">Top HCO</Trans></p>
              <TopRecipients recipients={hco} />
            </React.Fragment>
            }

            <hr />
            <p className="details"><Trans i18nKey="pharma_categories">Kategorien</Trans></p>
            {totalCategories}
            <hr />

            <div className="recipient_error">
              <p className="legende"><b><Trans i18nKey="recipient_disclaimer_source_title" /></b><br />
                <Trans i18nKey="recipient_disclaimer_source_text" />
              </p>

              <p className="legende"><b><Trans i18nKey="recipient_disclaimer_objection_title" /></b><br />
                <Trans i18nKey="recipient_disclaimer_objection_text" /> <a href={`mailto:info@pharmagelder.ch?subject=Pharmagelder&body=${lb}${lb}${lb}Zur Rückverfolgung (bitte nicht entfernen):${lb}${pharma.id} - ${pharma.name} - ${pharma.address}`} target='_blank' rel="noopener noreferrer">info@pharmagelder.ch</a>
              </p>
            </div>

            <hr />
            <div className="quelle">
              <p><strong><Trans i18nKey="source">Quelle</Trans></strong></p>
              <ul>
                {sourcesMap}
              </ul>
            </div>
          </div>
        );
      }
    }
  }
}

export default withTranslation()(Pharma);
