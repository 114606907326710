import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import Helpers from '../helpers/helpers.js';

import NumberFormat from 'react-number-format';

function Recipient(props) {
  return (
    <div className="results_list">
      <Link to={`/recipient/${props.data.id}-${Helpers.formatLink(props.data.name)}.html`}>
        <div className="results_container">
          <div className="person"><strong>{props.data.name}</strong> <br /> {!!(props.data.address) ? `${props.data.address}, ` : ""} {props.data.location}</div>
          <div className="specific"><strong><NumberFormat value={props.data.total} displayType={'text'} thousandSeparator={`'`} decimalSeparator={'.'} /> <Trans i18nKey="currency">Fr.</Trans></strong></div>
        </div>
      </Link>
    </div>
  )
}

class RecipientList extends Component {

  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  render() {
    if (this.props.data.length > 0) {
      //Map Recipients
      const list = this.props.data.map((recipient) =>
        <Recipient data={recipient} key={recipient.id} />
      );

      //Calc Count
      // let recipient_count = this.props.data.length === 100 ? '>' + this.props.data.length : this.props.data.length;

      return (
        <div className="results_block">
          {list}
        </div>
      )
    }
    else {
      return null;
    }
  }

}


export default RecipientList;